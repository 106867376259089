import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { Typography, Paper } from "@material-ui/core";
import { CustomIcon } from "components/common/CustomIcon/CustomIcon";
import "./Marker.scss";

const Marker = (props) => {
  const { item, selectedLocationMarker, handleSelect, ...rest } = props;
  return (
    <Tooltip
      open={item.title === selectedLocationMarker}
      enterTouchDelay={0}
      title={
        <div className="tooltip-container">
          <Typography
            color="inherit"
            variant="h6"
            style={{ fontWeight: "900" }}
          >
            {item.title}
          </Typography>
          <div className="address-section">
            <CustomIcon iconName={"location-pin"} size={"sm"} />
            <Typography
              color="inherit"
              variant="body2"
              className="address-content"
            >
              {item.address}
            </Typography>
          </div>
          <div className="operating-hour-section">
            <CustomIcon iconName={"time-pin"} size={"xs"} />
            <Typography
              color="inherit"
              variant="body2"
              className="operating-hour-content"
            >
              {item.operatingHour}
            </Typography>
          </div>
          {item.storageAvailable === true ? (
            <Typography color="inherit" variant="body2" className="available">
              {item.storeStatus}
            </Typography>
          ) : (
            <Typography
              color="inherit"
              variant="body2"
              className="not-available"
            >
              {item.storeStatus}
            </Typography>
          )}
        </div>
      }
      placement="top"
    >
      <div
        className={`Marker cursor`}
        {...rest}
        onClick={() => handleSelect(item)}
        // to make sure the marker is not blocked by other icon
        style={{ zIndex: 2 }}
      >
        <CustomIcon iconName="fm-logo" size="xl" />
      </div>
    </Tooltip>
  );
};

export { Marker };
