/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: "ap-southeast-1",
  aws_cognito_identity_pool_id:
    "ap-southeast-1:de0cd55d-08a3-4153-8238-4be90560e605",
  aws_cognito_region: "ap-southeast-1",
  aws_user_pools_id: "ap-southeast-1_2uklaYtbo",
  aws_user_pools_web_client_id: "1r007or0u3tebuajc4br8q8re7",
  oauth: {},
  aws_appsync_graphqlEndpoint:
    "https://ozu7mplstrbgnapuuqxnkqwf2u.appsync-api.ap-southeast-1.amazonaws.com/graphql",
  aws_appsync_region: "ap-southeast-1",
  aws_appsync_authenticationType: "API_KEY",
  aws_appsync_apiKey: "da2-j464l5wfzbgojo643hlphoxvaa",
  aws_user_files_s3_bucket: "pudoparcel-webassets-prod",
  aws_user_files_s3_bucket_region: "ap-southeast-1",
};

export default awsmobile;
