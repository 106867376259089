import React from "react";
import TextField from "@material-ui/core/TextField";
import { CustomIcon } from "components/common/CustomIcon/CustomIcon";
import "./CustomTextField.scss";

const CustomTextField = (props) => {
  const { className, iconName, iconSize, ...rest } = props;
  return (
    <div className={`CustomTextField ${className}`}>
      {iconName && <CustomIcon iconName={iconName} size={iconSize} />}
      <TextField
        InputProps={{
          disableUnderline: true,
        }}
        {...rest}
      />
    </div>
  );
};

export { CustomTextField };
