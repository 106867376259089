import createTheme from "@material-ui/core/styles/createTheme";

const fontFamily = ["OpenSansRegular"];
const colors = {
  primary: "#008CDB",
  primaryLight: "#1e95da",
  primaryDark: "#007ABF",
  success: "#009C6A",
  danger: "#D94D4C",
  grey100: "#F5F6F9",
  grey200: "#edf2f7",
  grey300: "#e2e8f0",
  grey400: "#cbd5e0",
  grey500: "#a0aec0",
  grey600: "#718096",
  grey700: "#4a5568",
  grey800: "#2d3748",
  grey900: "#1a202c",
};
export const theme = createTheme({
  palette: {
    primary: { main: colors.primary },
    secondary: { main: colors.success },
    grey: {
      100: colors.grey100,
      200: colors.grey200,
      300: colors.grey300,
      400: colors.grey400,
      500: colors.grey500,
      600: colors.grey600,
      700: colors.grey700,
      800: colors.grey800,
      900: colors.grey900,
    },
  },
  typography: {
    fontFamily: `${fontFamily.join(",")} !important`,
  },
  overrides: {
    MuiTypography: {
      h5: {
        fontSize: "1.1rem",
        fontWeight: "600",
        color: colors.primaryLight,
      },
      h6: {
        fontSize: "0.95rem",
        fontWeight: "500",
        color: `${colors.grey800}`,
      },
      subtitle1: {
        fontSize: "0.8rem",
        color: colors.grey500,
        lineHeight: `${1.3} !important`,
      },
      body1: {
        fontSize: "0.9rem",
        fontWeight: 500,
        color: colors.grey700,
        lineHeight: `${1.3} !important`,
      },
      body2: {
        fontSize: "0.8rem",
        fontWeight: 500,
        color: colors.grey500,
        lineHeight: `${1.3} !important`,
      },
    },
    MuiButton: {
      outlined: {
        border: `0.12rem solid ${colors.primary} !important`,
        color: `${colors.primary} !important`,
        backgroundColor: "#fff !important",
        width: "100%",
        padding: "1rem",
        fontWeight: "bold !important",
        lineHeight: "1.3rem",
      },
      contained: {
        textTransform: `none !important`,
        background: `${colors.primary} !important`,
        padding: "1rem",
        width: "100%",
        color: "#fff !important",
        border: "none !important",
        fontWeight: "bold !important",
        lineHeight: "1.3rem",
        overflow: "hidden",
      },
      text: {
        fontSize: "0.8rem",
        color: `${colors.primaryLight} !important`,
        textTransform: "inherit",
        fontWeight: "500 !important",
        lineHeight: "1.3rem",
        backgroundColor: "transparent !important",
      },
    },
    MuiAppBar: {
      root: {
        backgroundColor: "white !important",
      },
    },
    MuiTabs: {
      root: {
        minHeight: "2rem",
      },
      indicator: {
        // backgroundColor: `${colors.primaryLight} !important`,
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > span": {
          maxWidth: "4.5rem",
          width: "100%",
          backgroundColor: colors.primaryLight,
        },
      },
    },
    MuiTab: {
      root: {
        minHeight: "2rem",
      },
      wrapper: {
        textTransform: "capitalize",
        fontWeight: 600,
      },
    },
    MuiInputBase: {
      input: {
        fontSize: "0.9rem",
      },
    },
    MuiPaper: {
      outlined: {
        border: `0.13rem solid ${colors.grey300}`,
        borderRadius: "0.2rem",
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.grey400,
      },
    },
  },
});
