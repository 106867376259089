import React, { useState } from "react";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { CustomIcon } from "components/common/CustomIcon/CustomIcon";
import { useHistory } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";

import "./SearchBar.scss";

const SearchBar = (props) => {
  const {
    leftIcon = null,
    searchFilter,
    setSearchFilter,
    variant,
    title,
    customProps,
    handleOrderChange,
  } = props;
  const history = useHistory();

  const handleSubmit = (e) => {
    if (searchFilter === "" || searchFilter === null) {
      return;
    }
    if (variant === "search-order") {
      return;
    }
    if (variant === "box" && e.key === "Enter") {
      history.push({ pathname: "/track", search: `trn=${searchFilter}` });
    }
  };
  const handleOnClickSearch = () => {
    if (searchFilter === "" || searchFilter === null) {
      return;
    }
    history.push({ pathname: "/track", search: `trn=${searchFilter}` });
  };

  return (
    <div className={`SearchBar ${variant === "box" ? "box" : "search"}`}>
      {variant === "box" && (
        <div className="pre-icon">
          <CustomIcon iconName="parcel" size="md" />
        </div>
      )}
      {variant !== "box" && !!leftIcon && (
        <div className="pre-icon">{leftIcon}</div>
      )}
      {variant === "search-order" ? (
        <div className="search-bar__form-form">
          <InputBase
            placeholder={title}
            className="search-input"
            inputProps={{ "aria-label": "search" }}
            onKeyPress={(e) => handleSubmit(e)}
            value={searchFilter}
            onChange={handleOrderChange}
            {...customProps}
          />
        </div>
      ) : (
        <form action="" class="search-bar__form-form">
          <InputBase
            placeholder={title}
            className="search-input"
            inputProps={{ "aria-label": "search" }}
            onKeyPress={(e) => handleSubmit(e)}
            value={searchFilter}
            onChange={(e) => setSearchFilter(e.target.value)}
            {...customProps}
            type="search"
            name="trn"
          />
        </form>
      )}
      {searchFilter !== "" && searchFilter !== null ? (
        <div className="end-icon">
          <a style={{ cursor: "pointer" }} onClick={() => setSearchFilter("")}>
            <CloseOutlinedIcon />
          </a>
        </div>
      ) : variant === "search" || variant === "search-order" ? (
        <div className="end-icon">
          <SearchIcon />
        </div>
      ) : null}
      {variant === "box" && (
        <a style={{ cursor: "pointer" }} onClick={handleOnClickSearch}>
          <div className="end-icon">
            <SearchIcon />
          </div>
        </a>
      )}
    </div>
  );
};

export { SearchBar };
