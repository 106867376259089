import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { getParcelCartList, getUserProfile } from "graphql/queries";

const StoreContext = React.createContext({
  store: "",
});

const StoreProvider = ({ children }) => {
  const [selectedDropOffStore, setSelectedDropOffStore] = useState("");
  const [selectedCollectionStore, setSelectedCollectionStore] = useState("");
  const [cartCount, setCartCount] = useState(
    sessionStorage.getItem("cartCount")
  );

  useEffect(() => {
    if (selectedDropOffStore !== "") {
      sessionStorage.setItem(
        "selectedDropOffStore",
        JSON.stringify(selectedDropOffStore)
      );
    } else if (sessionStorage.getItem("selectedDropOffStore") !== null) {
      setSelectedDropOffStore(
        JSON.parse(sessionStorage.getItem("selectedDropOffStore"))
      );
    }
  }, [selectedDropOffStore]);

  useEffect(() => {
    if (selectedCollectionStore !== "") {
      sessionStorage.setItem(
        "selectedCollectionStore",
        JSON.stringify(selectedCollectionStore)
      );
    } else if (sessionStorage.getItem("selectedCollectionStore") !== null) {
      setSelectedCollectionStore(
        JSON.parse(sessionStorage.getItem("selectedCollectionStore"))
      );
    }
  }, [selectedCollectionStore]);

  async function getCartCount() {
    let sessionId = sessionStorage.getItem("sessionId");
    const res = await API.graphql(
      graphqlOperation(getParcelCartList, { sessionId: sessionId })
    );
    if (res && res.data.getParcelCartList.message === "Success") {
      let cartRes = res.data.getParcelCartList.parcelOrderCartItemList;
      sessionStorage.setItem("cartCount", cartRes.length);
      setCartCount(cartRes.length);
    }
  }

  async function fetchProfileData() {
    let signInData = JSON.parse(sessionStorage.getItem("signInData"));
    const res = await API.graphql(
      graphqlOperation(getUserProfile, {
        token: signInData.token,
      })
    );
    if (res && res.data.getUserProfile.status === "true") {
      let profileRes = res.data.getUserProfile.userData;
      signInData = {
        ...signInData,
        email: profileRes.email,
        mobileNumber: profileRes.mobileNumber,
        name: profileRes.firstName + " " + profileRes.lastName,
      };
      sessionStorage.setItem("signInData", JSON.stringify(signInData));
      localStorage.setItem("signInData", JSON.stringify(signInData));
    }
  }
  return (
    <>
      <StoreContext.Provider
        value={{
          selectedDropOffStore,
          setSelectedDropOffStore,
          selectedCollectionStore,
          setSelectedCollectionStore,
          cartCount,
          setCartCount,
          getCartCount,
          fetchProfileData,
        }}
      >
        {children}
      </StoreContext.Provider>
    </>
  );
};

const useStoreContext = () => {
  return React.useContext(StoreContext);
};

export { StoreProvider, useStoreContext };
