import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";

const ButtonLoader = (props) => {
  const { customStyle } = props;

  return (
    <CircularProgress
      style={{
        color: "white",
        height: "1.3rem",
        width: "1.3rem",
        ...customStyle,
      }}
    />
  );
};
export { ButtonLoader };

const LoadingSpinner = () => {
  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      style={{ height: "60vh", padding: "10px" }}
    >
      <CircularProgress />
    </Grid>
  );
};

export default LoadingSpinner;
