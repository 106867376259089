import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "reportWebVitals";
import aws_exports from "./aws-exports";
import Amplify from "aws-amplify";
import { StoreProvider } from "contexts/StoreContext";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import "scss/style.scss";

Amplify.configure(aws_exports);

ReactDOM.render(
  // <React.StrictMode>
  <BrowserRouter>
    <StoreProvider>
      <App />
    </StoreProvider>
  </BrowserRouter>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
