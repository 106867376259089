import React, { Suspense, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Route, Switch, BrowserRouter, useHistory } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import { CustomSnackbar } from "components/common/CustomSnackbar/CustomSnackbar";
import { Modal } from "components/common/Modal/Modal";
import LocationPickerModal from "components/home/LocationPickerModal/LocationPickerModal";
import { ConfirmBookingModal } from "components/confirmBooking/ConfirmBookingModal/ConfirmBookingModal";
import { Background } from "components/common/Background/Background";
import { theme } from "theme";
import { v4 as uuidv4 } from "uuid";
import { API, graphqlOperation } from "aws-amplify";
import { getPriceList, getUserProfile } from "graphql/queries";
import { checkUserSSOToken } from "graphql/mutations";
import ScrollToTop from "hooks/ScrollToTop";
import { useStoreContext } from "contexts/StoreContext";
import { setCustomSnackbarState } from "states/customSnackbarState";
import ConsentPopUp from "components/consentPopUp/ConsentPopUp";

const TheLayout = React.lazy(() =>
  import("components/common/Containers/TheLayout")
);

const loading = (
  <Background style={{ textAlign: "center", height: "100vh" }} color="grey100">
    <CircularProgress color="primary" className="suspense-spinner" />
  </Background>
);

const App = () => {
  const history = useHistory();
  const { getCartCount } = useStoreContext();
  const [showConsentPopup, setShowConsentPopup] = useState(false);

  useEffect(() => {
    let isRedirect = sessionStorage.getItem("loginRedirect");
    // if found signInData in local, auto login
    if (localStorage.getItem("signInData") !== null) {
      let signInData = JSON.parse(localStorage.getItem("signInData"));
      // if ttl < time now invalidate all signIn data
      if (new Date().getTime() > signInData.expiry) {
        localStorage.removeItem("signInData");
        return;
      }

      sessionStorage.setItem("signInData", JSON.stringify(signInData));
      sessionStorage.setItem("sessionId", signInData?.sessionId);
    }
    //if redirect from mobile app, auto login
    // else if (isRedirect === "True") {
    //   let loginToken = sessionStorage.getItem("token");
    //   if (loginToken !== null) {
    //     checkUserToken(loginToken, true);
    //   }
    // }
    // generate uuid as session id for guest
    else if (sessionStorage.getItem("sessionId") === null) {
      sessionStorage.setItem("sessionId", uuidv4());
    }
    //if redirect from mobile app, auto login
    // if (isRedirect === "True") {
    //   let loginToken = sessionStorage.getItem("token");
    //   if (loginToken !== null) {
    //     checkUserToken(loginToken, true);
    //     sessionStorage.setItem("fromMobile", "true");
    //   }
    // }
  }, []);
  useEffect(() => {
    async function fetchProfileData() {
      let signInData = JSON.parse(sessionStorage.getItem("signInData"));
      const res = await API.graphql(
        graphqlOperation(getUserProfile, {
          token: signInData.token,
        })
      );
      if (res && res.data.getUserProfile.status === "true") {
        let profileRes = res.data.getUserProfile.userData;
        signInData = {
          ...signInData,
          email: profileRes.email,
          mobileNumber: profileRes.mobileNumber,
          name: profileRes.firstName + " " + profileRes.lastName,
        };
        sessionStorage.setItem("signInData", JSON.stringify(signInData));
        localStorage.setItem("signInData", JSON.stringify(signInData));
      }
      sessionStorage.setItem("isFirstLoad", false);
    }
    // fetch user profile and cart count once on first load if the user stay logged in to update old data cache
    if (
      sessionStorage.getItem("isFirstLoad") === null &&
      localStorage.getItem("signInData") !== null
    ) {
      fetchProfileData();
      getCartCount();
    }
  }, []);

  useEffect(() => {
    async function fetchPriceData() {
      const res = await API.graphql(graphqlOperation(getPriceList));
      let priceConfig = { FlyerBag: 0, NormalRate: 0, MemberRate: 0 };
      if (res && res.data.getPriceList.status === true) {
        let priceList = res.data.getPriceList.priceList;

        priceList.forEach((item) => {
          if (item.type === "FlyerBag") {
            priceConfig = { ...priceConfig, FlyerBag: parseFloat(item.value) };
          }
          if (item.type === "NormalRate") {
            priceConfig = {
              ...priceConfig,
              NormalRate: parseFloat(item.value),
            };
          }
          if (item.type === "MemberRate") {
            priceConfig = {
              ...priceConfig,
              MemberRate: parseFloat(item.value),
            };
          }
        });
      }
      sessionStorage.setItem("priceConfig", JSON.stringify(priceConfig));
    }
    if (sessionStorage.getItem("priceConfig") === null) {
      fetchPriceData();
    }
  }, []);

  async function checkUserToken(loginToken, _loginRedirect) {
    const res = await API.graphql(
      graphqlOperation(checkUserSSOToken, {
        token: loginToken,
        platform: "FM",
        loginRedirect: _loginRedirect,
      })
    );
    if (res && res.data.checkUserSSOToken.status === true) {
      let signInRes = res.data.checkUserSSOToken;
      // save user info to local storage to Persist a Logged-in User
      // set the signInData ttl to 1 day
      localStorage.setItem(
        "signInData",
        JSON.stringify({
          ...signInRes,
          expiry: new Date().getTime() + 8.64e7,
        })
      );
      // save user info to session and replace session id
      sessionStorage.setItem("signInData", JSON.stringify(signInRes));
      sessionStorage.setItem("sessionId", signInRes?.sessionId);
      // remove indicator to prevent infinite loop
      sessionStorage.removeItem("loginRedirect");
      sessionStorage.removeItem("token");
      getCartCount();
      setShowConsentPopup(false);
    } else if (
      res &&
      res.data.checkUserSSOToken.status === false &&
      res.data.checkUserSSOToken.firstTimeLogin === true
    ) {
      //call the consent popup, user is first time login
      setShowConsentPopup(true);
    } else {
      setCustomSnackbarState({
        show: true,
        title: res.data.checkUserSSOToken.message,
        variant: "error",
      });
    }
  }

  const handleAgree = () => {
    let loginToken = sessionStorage.getItem("token");
    if (loginToken !== null) {
      checkUserToken(loginToken);
    }
  };

  return (
    <ScrollToTop>
      <MuiThemeProvider theme={theme}>
        <Suspense fallback={loading}>
          <CustomSnackbar />
          <ConfirmBookingModal />
          <LocationPickerModal />
          <ConsentPopUp
            showPopup={showConsentPopup}
            setShowPopup={setShowConsentPopup}
            onClickAgree={handleAgree}
          />
          <Switch>
            <Route
              path="/"
              name="Home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </Suspense>
      </MuiThemeProvider>
    </ScrollToTop>
  );
};
export default App;
