/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const adminGetCustomerDetail = /* GraphQL */ `
  query AdminGetCustomerDetail($customerId: String) {
    adminGetCustomerDetail(customerId: $customerId) {
      customer {
        accountNo
        actionBy
        actionDate
        actionReasonCode
        activated
        address
        address2
        areaOfInterest
        birthday
        city
        clubCode
        consentPersonalData
        country
        createdAt
        createdBy
        custClubCode
        custSchemeCode
        customerDeviceEndpoint
        customerDeviceToken
        customerId
        email
        employmentStatus
        facebookID
        firstName
        gender
        hasRewarded
        identityCard
        isBlocked
        isFMMember
        isFacebook
        joinDate
        joinTime
        lastName
        maritalStatus
        mobileNumber
        nationality
        password
        personalIncomeLevel
        postal
        profilePicture
        promoCode
        qrCode
        race
        secondaryEmail
        signedUpDate
        signedUpTime
        staffId
        staffJoinDate
        state
        tagCode
        updatedAt
        updatedBy
      }
      message
      orders {
        createdAt
        createdBy
        discountType
        dropOffDateTime
        dropOffStoreName
        flyerBagCancellable
        flyerBagPrice
        flyerBagQuantity
        flyerBagSize
        flyerBagStatus
        flyerBagTotalPrice
        grandTotal
        orderDate
        orderNumber
        orderTxnId
        originalOrderNumber
        parcelOrderId
        parcelTotalPrice
        parcelType
        paymentMethod
        paymentType
        promoCode
        promoCodeDiscount
        refundAmount
        refundStatus
        senderAccountNo
        senderCustomerId
        senderEmail
        senderMobileNumber
        senderName
        status
        totalQuantity
        transactionId
        transactionNo
        transactionNumber
        updatedAt
        updatedBy
      }
      status
    }
  }
`;
export const adminGetDownloadJobStatus = /* GraphQL */ `
  query AdminGetDownloadJobStatus($fileDownloadStatusId: String) {
    adminGetDownloadJobStatus(fileDownloadStatusId: $fileDownloadStatusId) {
      createdAt
      fileDownloadStatusId
      message
      module
      objectPresignedUrl
      status
      updatedAt
    }
  }
`;
export const adminGetParcelOrderDetail = /* GraphQL */ `
  query AdminGetParcelOrderDetail($parcelOrderDetailId: String) {
    adminGetParcelOrderDetail(parcelOrderDetailId: $parcelOrderDetailId) {
      message
      otherParcelDetails {
        collectionDateTime
        collectionStoreCode
        collectionStoreName
        createdAt
        createdBy
        deliveryPrice
        discountType
        dropOffDateTime
        dropOffStoreCode
        dropOffStoreName
        orderDate
        orderNumber
        orderTxnId
        orderType
        originalOrderNumber
        parcelAmount
        parcelCategory
        parcelOrderDetailId
        parcelOrderId
        parcelSize
        parcelType
        parcelValue
        paymentMethod
        paymentSuccess
        promoCodeDiscount
        receiverAccountNumber
        receiverEmail
        receiverMobileNumber
        receiverName
        refundAmount
        refundReason
        refundReasonCode
        refundStatus
        senderAccountNo
        senderMobileNumber
        status
        trackerNumber
        transactionId
        transactionNumber
        updatedAt
        updatedBy
      }
      parcel {
        createdAt
        createdBy
        discountType
        dropOffDateTime
        dropOffStoreName
        flyerBagCancellable
        flyerBagPrice
        flyerBagQuantity
        flyerBagSize
        flyerBagStatus
        flyerBagTotalPrice
        grandTotal
        orderDate
        orderNumber
        orderTxnId
        originalOrderNumber
        parcelOrderId
        parcelTotalPrice
        parcelType
        paymentMethod
        paymentType
        promoCode
        promoCodeDiscount
        refundAmount
        refundStatus
        senderAccountNo
        senderCustomerId
        senderEmail
        senderMobileNumber
        senderName
        status
        totalQuantity
        transactionId
        transactionNo
        transactionNumber
        updatedAt
        updatedBy
      }
      parcelDetail {
        collectionDateTime
        collectionStoreCode
        collectionStoreName
        createdAt
        createdBy
        deliveryPrice
        discountType
        dropOffDateTime
        dropOffStoreCode
        dropOffStoreName
        orderDate
        orderNumber
        orderTxnId
        orderType
        originalOrderNumber
        parcelAmount
        parcelCategory
        parcelOrderDetailId
        parcelOrderId
        parcelSize
        parcelType
        parcelValue
        paymentMethod
        paymentSuccess
        promoCodeDiscount
        receiverAccountNumber
        receiverEmail
        receiverMobileNumber
        receiverName
        refundAmount
        refundReason
        refundReasonCode
        refundStatus
        senderAccountNo
        senderMobileNumber
        status
        trackerNumber
        transactionId
        transactionNumber
        updatedAt
        updatedBy
      }
      parcelStatus {
        GPS01
        GPS02
        PUPRequestNo
        attemptTime
        cancelBy
        cancelReason
        comments
        createdAt
        createdBy
        customerAcctNo
        customerPUPRefNo01
        date
        destCity
        destCountry
        destPostcode
        dropBoxID
        employeeID
        handlingCode
        kdsCreatedAt
        locationID
        originPostcode
        packagingType
        parcelOrderDetailId
        parcelOrderId
        parcelStatusId
        reasonCode
        refundAmount
        refundBy
        refundReason
        routeID
        routingCode
        scanCode
        scanType
        serviceType
        status
        stopType
        totalPackages
        totalWeight
        trackerNumber
        updatedAt
        updatedBy
      }
      status
    }
  }
`;
export const adminListUserMatrix = /* GraphQL */ `
  query AdminListUserMatrix($module: String, $userGroup: String) {
    adminListUserMatrix(module: $module, userGroup: $userGroup) {
      items {
        __typename
        canAdd
        canDelete
        canEdit
        canList
        canView
        createdAt
        createdBy
        module
        updatedAt
        updatedBy
        userGroup
        userMatrixId
      }
      message
      status
    }
  }
`;
export const checkParcelOrderPaymentStatus = /* GraphQL */ `
  query CheckParcelOrderPaymentStatus($orderNumber: String) {
    checkParcelOrderPaymentStatus(orderNumber: $orderNumber) {
      isReturnOrder
      message
      status
      trackerNumber
    }
  }
`;
export const customSearchFlyerBagHistory = /* GraphQL */ `
  query CustomSearchFlyerBagHistory(
    $filter: SearchableFlyerBagHistoryFilterInput
    $from: Int
    $limit: Int
    $nextToken: String
    $sort: SearchableFlyerBagHistorySortInput
  ) {
    customSearchFlyerBagHistory(
      filter: $filter
      from: $from
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        accountNo
        actionDate
        benefitTriggerId
        benefitTriggerType
        createdAt
        detailedMemberLedgerEntryNo
        expiryDate
        flyerBagHistoryId
        flyerBagQuantity
        isNav
        memberLedgerEntryNo
        modifiedBy
        netVoucherValue
        orderNumber
        posTerminal
        storeCode
        txnId
        updatedAt
        voucherCode
        voucherStatus
      }
      nextToken
      total
    }
  }
`;
export const getDownloadJobStatus = /* GraphQL */ `
  query GetDownloadJobStatus($fileDownloadStatusId: String) {
    getDownloadJobStatus(fileDownloadStatusId: $fileDownloadStatusId) {
      createdAt
      fileDownloadStatusId
      message
      module
      objectPresignedUrl
      status
      updatedAt
    }
  }
`;
export const getFileUploadParcelList = /* GraphQL */ `
  query GetFileUploadParcelList($pollingId: String) {
    getFileUploadParcelList(pollingId: $pollingId) {
      message
      parcelList {
        collectionStore
        collectionStoreName
        dropOffStore
        dropOffStoreName
        hasError
        parcelCartId
        parcelValue
        receiverEmail
        receiverMobileNumber
        receiverName
      }
      status
    }
  }
`;
export const getFileUploadStatus = /* GraphQL */ `
  query GetFileUploadStatus($fileName: String) {
    getFileUploadStatus(fileName: $fileName) {
      message
      pollingId
      status
    }
  }
`;
export const getFlyerBagOption = /* GraphQL */ `
  query GetFlyerBagOption {
    getFlyerBagOption {
      flyerBagList {
        description
        flyerBagCode
        label
        value
      }
      message
      status
    }
  }
`;
export const getLandingPageBannerCache = /* GraphQL */ `
  query GetLandingPageBannerCache {
    getLandingPageBannerCache {
      items {
        actionTitle
        buttonAction
        buttonLabel
        buttonType
        createdAt
        createdBy
        description
        endDate
        image
        isDisabled
        landingPageBannerId
        listBanners {
          description
          id
          image
          sequence
          title
        }
        mobileImage
        pageLayout
        referenceId
        sequenceOrder
        startDate
        title
        updatedAt
        updatedBy
        validationPeriodId
      }
      message
      status
    }
  }
`;
export const getLandingPageNewsCache = /* GraphQL */ `
  query GetLandingPageNewsCache {
    getLandingPageNewsCache {
      items {
        actionTitle
        buttonAction
        buttonLabel
        buttonType
        createdAt
        createdBy
        description
        endDate
        image
        isDisabled
        landingPageNewsId
        listNews {
          description
          id
          image
          sequence
          title
        }
        pageLayout
        referenceId
        sequenceOrder
        startDate
        title
        updatedAt
        updatedBy
        validationPeriodId
      }
      message
      status
    }
  }
`;
export const getParcelCartDetail = /* GraphQL */ `
  query GetParcelCartDetail($parcelCartId: String, $sessionId: String) {
    getParcelCartDetail(parcelCartId: $parcelCartId, sessionId: $sessionId) {
      collectionStore
      collectionStoreName
      dropOffStore
      dropOffStoreName
      hasError
      message
      parcelCartId
      parcelValue
      receiverEmail
      receiverMobileNumber
      receiverName
      senderEmail
      senderMobileNumber
      senderName
      status
    }
  }
`;
export const getParcelCartList = /* GraphQL */ `
  query GetParcelCartList($sessionId: String) {
    getParcelCartList(sessionId: $sessionId) {
      message
      parcelOrderCartItemList {
        collectionStore
        collectionStoreName
        dropOffStore
        dropOffStoreName
        hasError
        message
        parcelCartId
        parcelValue
        receiverEmail
        receiverMobileNumber
        receiverName
        senderEmail
        senderMobileNumber
        senderName
        status
      }
      status
    }
  }
`;
export const getParcelOrderDetail = /* GraphQL */ `
  query GetParcelOrderDetail($orderNumber: String, $sessionId: String) {
    getParcelOrderDetail(orderNumber: $orderNumber, sessionId: $sessionId) {
      cnPdfLink
      flyerBagCancellable
      flyerBagPrice
      flyerBagQty
      flyerBagStatus
      grandTotal
      message
      orderDateTime
      orderNumber
      orderTotalAmount
      parcelDetailList {
        collectionStore
        collectionStoreName
        dropOffStore
        dropOffStoreName
        parcelOrderDetailId
        parcelPrice
        parcelStatus
        receiverEmail
        receiverMobileNumber
        receiverName
        senderEmail
        senderMobileNumber
        senderName
        trackerNumber
      }
      parcelOrderId
      promoCodeDiscount
      qrString
      status
      subtotalAfterDiscount
      totalParcel
    }
  }
`;
export const getParcelOrderList = /* GraphQL */ `
  query GetParcelOrderList(
    $keyword: String
    $nextToken: String
    $sessionId: String
    $sorting: String
    $status: String
  ) {
    getParcelOrderList(
      keyword: $keyword
      nextToken: $nextToken
      sessionId: $sessionId
      sorting: $sorting
      status: $status
    ) {
      message
      nextToken
      parcelOrderList {
        collectionStoreName
        dropOffStoreName
        id
        orderOrTrackingNumber
        parcelAmount
        parcelCompleteDateTime
        receiverMobileNumber
        receiverName
        statusMessage
        totalParcel
      }
      status
      total
    }
  }
`;
export const getParcelOrderPaymentMessage = /* GraphQL */ `
  query GetParcelOrderPaymentMessage($messageId: String) {
    getParcelOrderPaymentMessage(messageId: $messageId) {
      createdAt
      errorMessage
      gatewayPaymentId
      gatewayPaymentParams
      gatewayPaymentUrl
      messageId
      orderNumber
      parcelOrderId
      parcelOrderPaymentMessageId
      updatedAt
    }
  }
`;
export const getParcelTrackingStatus = /* GraphQL */ `
  query GetParcelTrackingStatus($sessionId: String, $trackerNumber: String) {
    getParcelTrackingStatus(
      sessionId: $sessionId
      trackerNumber: $trackerNumber
    ) {
      cancelReason
      collectionStoreId
      collectionStoreName
      deliveryStatus
      dropOffStoreId
      dropOffStoreName
      history {
        actionDateTime
        description
        destination
      }
      message
      orderNumber
      parcelMessage
      parcelOriginalPrice
      parcelPrice
      parcelStatus
      parcelStatusDateTime
      parcelStatusDescription
      receiverMobileNumber
      receiverName
      refundAmount
      senderMobileNumber
      senderName
      showReturnOption
      sortingFacilityAddress
      sortingFacilityLink
      status
      totalParcel
      trackerNumber
    }
  }
`;
export const getPastParcelDetail = /* GraphQL */ `
  query GetPastParcelDetail($sessionId: String, $trackerNumber: String) {
    getPastParcelDetail(sessionId: $sessionId, trackerNumber: $trackerNumber) {
      collectionStoreAddress
      collectionStoreName
      completedDateTime
      createdDateTime
      dropOffStoreAddress
      dropOffStoreName
      message
      parcelStatus
      parcelStatusDescription
      receiverMobileNumber
      receiverName
      status
      trackerNumber
    }
  }
`;
export const getPriceList = /* GraphQL */ `
  query GetPriceList {
    getPriceList {
      message
      priceList {
        type
        value
      }
      status
    }
  }
`;
export const getStoreList = /* GraphQL */ `
  query GetStoreList {
    getStoreList {
      message
      status
      storeList {
        address
        distance
        lat
        lng
        mappingStore
        operatingHour
        storageAvailable
        storeId
        storeStatus
        title
      }
    }
  }
`;
export const getUserProfile = /* GraphQL */ `
  query GetUserProfile($token: String) {
    getUserProfile(token: $token) {
      message
      status
      userData {
        accountNo
        actionBy
        actionDate
        actionReasonCode
        activated
        address
        address2
        areaOfInterest
        birthday
        city
        clubCode
        consentPersonalData
        country
        createdAt
        createdBy
        custClubCode
        custSchemeCode
        customerDeviceEndpoint
        customerDeviceToken
        customerId
        email
        employmentStatus
        facebookID
        firstName
        gender
        hasRewarded
        identityCard
        isBlocked
        isFMMember
        isFacebook
        joinDate
        joinTime
        lastName
        maritalStatus
        mobileNumber
        nationality
        password
        personalIncomeLevel
        postal
        profilePicture
        promoCode
        qrCode
        race
        secondaryEmail
        signedUpDate
        signedUpTime
        staffId
        staffJoinDate
        state
        tagCode
        updatedAt
        updatedBy
      }
    }
  }
`;
export const getViewParcelOrderDetail = /* GraphQL */ `
  query GetViewParcelOrderDetail($orderNumber: String, $sessionId: String) {
    getViewParcelOrderDetail(orderNumber: $orderNumber, sessionId: $sessionId) {
      flyerBagPrice
      flyerBagQty
      flyerBagStatus
      grandTotal
      message
      orderDateTime
      orderNumber
      orderTotalAmount
      parcelDetailList {
        collectionStore
        collectionStoreName
        dropOffStore
        dropOffStoreName
        parcelOrderDetailId
        parcelPrice
        parcelStatus
        receiverEmail
        receiverMobileNumber
        receiverName
        senderEmail
        senderMobileNumber
        senderName
        trackerNumber
      }
      parcelOrderId
      promoCodeDiscount
      status
      subtotalAfterDiscount
      totalParcel
    }
  }
`;
export const kdsGetFlyer = /* GraphQL */ `
  query KdsGetFlyer($orderNumber: String) {
    kdsGetFlyer(orderNumber: $orderNumber) {
      flyerBagQuantity
      message
      status
    }
  }
`;
export const searchCustomers = /* GraphQL */ `
  query SearchCustomers(
    $filter: SearchCustomersFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        accountNo
        actionBy
        actionDate
        actionReasonCode
        activated
        address
        address2
        areaOfInterest
        birthday
        city
        clubCode
        consentPersonalData
        country
        createdAt
        createdBy
        custClubCode
        custSchemeCode
        customerDeviceEndpoint
        customerDeviceToken
        customerId
        email
        employmentStatus
        facebookID
        firstName
        gender
        hasRewarded
        identityCard
        isBlocked
        isFMMember
        isFacebook
        joinDate
        joinTime
        lastName
        maritalStatus
        mobileNumber
        nationality
        password
        personalIncomeLevel
        postal
        profilePicture
        promoCode
        qrCode
        race
        secondaryEmail
        signedUpDate
        signedUpTime
        staffId
        staffJoinDate
        state
        tagCode
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchParcelOrderDetails = /* GraphQL */ `
  query SearchParcelOrderDetails(
    $filter: SearchParcelOrderDetailsFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchParcelOrderDetails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        collectionDateTime
        collectionStoreCode
        collectionStoreName
        createdAt
        createdBy
        deliveryPrice
        discountType
        dropOffDateTime
        dropOffStoreCode
        dropOffStoreName
        orderDate
        orderNumber
        orderTxnId
        orderType
        originalOrderNumber
        parcelAmount
        parcelCategory
        parcelOrderDetailId
        parcelOrderId
        parcelSize
        parcelType
        parcelValue
        paymentMethod
        paymentSuccess
        promoCodeDiscount
        receiverAccountNumber
        receiverEmail
        receiverMobileNumber
        receiverName
        refundAmount
        refundReason
        refundReasonCode
        refundStatus
        senderAccountNo
        senderMobileNumber
        status
        trackerNumber
        transactionId
        transactionNumber
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchParcelOrders = /* GraphQL */ `
  query SearchParcelOrders(
    $filter: SearchParcelOrdersFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchParcelOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        createdAt
        createdBy
        discountType
        dropOffDateTime
        dropOffStoreName
        flyerBagCancellable
        flyerBagPrice
        flyerBagQuantity
        flyerBagSize
        flyerBagStatus
        flyerBagTotalPrice
        grandTotal
        orderDate
        orderNumber
        orderTxnId
        originalOrderNumber
        parcelOrderId
        parcelTotalPrice
        parcelType
        paymentMethod
        paymentType
        promoCode
        promoCodeDiscount
        refundAmount
        refundStatus
        senderAccountNo
        senderCustomerId
        senderEmail
        senderMobileNumber
        senderName
        status
        totalQuantity
        transactionId
        transactionNo
        transactionNumber
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
export const searchRefundTransactions = /* GraphQL */ `
  query SearchRefundTransactions(
    $filter: SearchRefundTransactionFilter
    $limit: Int
    $nextToken: String
    $sort: ElasticSearchSortDirection
  ) {
    searchRefundTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sort: $sort
    ) {
      items {
        automatedRefundStatus
        completedBy
        createdAt
        createdBy
        customerName
        customerServiceStatus
        eGHLRefundStatus
        eghlRefundDateTime
        emailRefundStatus
        lastOrderTransitionDate
        manualCSRefundStatus
        manualRefundStatus
        order {
          collectionDateTime
          collectionStoreCode
          collectionStoreName
          createdAt
          createdBy
          deliveryPrice
          discountType
          dropOffDateTime
          dropOffStoreCode
          dropOffStoreName
          orderDate
          orderNumber
          orderTxnId
          orderType
          originalOrderNumber
          parcelAmount
          parcelCategory
          parcelOrderDetailId
          parcelOrderId
          parcelSize
          parcelType
          parcelValue
          paymentMethod
          paymentSuccess
          promoCodeDiscount
          receiverAccountNumber
          receiverEmail
          receiverMobileNumber
          receiverName
          refundAmount
          refundReason
          refundReasonCode
          refundStatus
          senderAccountNo
          senderMobileNumber
          status
          trackerNumber
          transactionId
          transactionNumber
          updatedAt
          updatedBy
        }
        orderDate
        orderNumber
        parcelOrderDetailId
        parcelOrderId
        paymentChannel
        paymentId
        paymentMethod
        redmineTicketNumber
        refundAmount
        refundDateTime
        refundReason
        refundTransactionId
        refundTxnId
        senderAccountNo
        senderMobileNumber
        status
        trackerNumber
        updatedAt
        updatedBy
      }
      nextToken
      total
    }
  }
`;
