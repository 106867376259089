import React, { useEffect, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useCustomSnackbarStore } from "states/customSnackbarState";
import "./CustomSnackbar.scss";

const CustomSnackbar = () => {
  const [customSnackbarStore] = useCustomSnackbarStore();
  const [showCustomSnackbar, setShowCustomSnackbar] = useState(false);

  useEffect(() => {
    if (customSnackbarStore.show) {
      setShowCustomSnackbar(true);
    }
  }, [customSnackbarStore]);

  const handleClose = () => {
    setShowCustomSnackbar(false);
  };

  const Alert = props => {
    return <MuiAlert elevation={2} variant="filled" {...props} />;
  };

  return (
    <Snackbar
      autoHideDuration={5000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={showCustomSnackbar}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={customSnackbarStore.variant || "info"}
      >
        {customSnackbarStore.title}
      </Alert>
    </Snackbar>
  );
};

export { CustomSnackbar };
