import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import { CustomIcon } from "components/common/CustomIcon/CustomIcon";
import { Typography, Button, Link } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { ButtonLoader } from "components/common/LoadingSpinner/LoadingSpinner";
import "./ConsentPopup.scss";

export default function ConsentPopUp(props) {
  const theme = useTheme();
  const isLgAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const { showPopup, setShowPopup, onClickAgree } = props;
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const handleClickTNC = () => {
    window.open(`${window.location.origin}/tnc`);
  };

  const renderTextGroup = (headerText, bodyText) => {
    return (
      <div className="consent-text-gp">
        <div className="text-gp-column">
          <DoneIcon className="confirm-icon" />
        </div>
        <div className="text-gp-column">
          <Typography variant="body1" className="header-text">
            {headerText}
          </Typography>
          <Typography variant="body1" className="body-text">
            {bodyText}
          </Typography>
        </div>
      </div>
    );
  };
  const handleOnClick = () => {
    setIsButtonLoading(true);
    onClickAgree();
  };
  return (
    <Dialog
      className="Consent-Modal"
      onClose={() => setShowPopup(false)}
      open={showPopup}
      fullScreen={isLgAndUp ? false : true}
    >
      <div className="consent-header-container">
        <Button
          startIcon={<CustomIcon iconName="arrow-left-grey" size="sm" />}
          onClick={() => setShowPopup(false)}
        ></Button>
        <Typography variant="h5" className="consent-header">
          Permission Authorization
        </Typography>
      </div>
      <div className="consent-content-container">
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/images/pudo-logo-3.png`}
            alt="logo"
            className="logo"
          />
          <Typography variant="body1" className="logo-text">
            Requesting for permission below
          </Typography>
        </div>

        <div className="consent-body">
          {renderTextGroup("Profile Name", "Your profile name")}
          {renderTextGroup("Mobile Number", "Your mobile number")}
          {renderTextGroup("Email", "Your email address")}
        </div>
        <div className="consent-footer-container">
          <Typography variant="body1">
            By Clicking the "Agree" button, you hereby express consent to the
            sharing of your Personal Data above with the Partner and transfer
            any of your Personal Data to a place in Malaysia and outside
            Malaysia subject to the following{" "}
            <Link onClick={handleClickTNC}>T&C</Link>
          </Typography>
          <Button
            variant="contained"
            style={{ marginTop: "10px" }}
            onClick={handleOnClick}
          >
            {isButtonLoading ? <ButtonLoader /> : "AGREE"}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
