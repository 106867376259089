import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import { CustomTextField } from "components/common/CustomTextField/CustomTextField";
import { useConfirmBookingModalStore } from "states/confirmBookingModalState";
import "./ConfirmBookingModal.scss";

const ConfirmBookingModal = () => {
  const [modalStore] = useConfirmBookingModalStore();
  const [showConfirmBookingModal, setShowConfirmBookingModal] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (modalStore.show) {
      setShowConfirmBookingModal(true);
    }
  }, [modalStore]);

  const handleClose = () => {
    setShowConfirmBookingModal(false);
  };

  const handleDoAction = () => {
    if (modalStore.action) {
      modalStore.action();
    }
    handleClose();
  };

  const handleDoSecondaryAction = () => {
    if (modalStore.secondaryAction) {
      modalStore.secondaryAction();
    }
    handleClose();
  };

  return (
    <Dialog
      className="ConfirmBookingModal"
      onClose={handleClose}
      open={showConfirmBookingModal}
    >
      <Typography variant="h5" className="title">
        <IconButton
          aria-label="close"
          className="btn-close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        Confirm Booking
      </Typography>
      <Typography variant="body1" className="subtitle">
        Please confirm the details of the receiver are correct.
      </Typography>

      <CustomTextField
        iconName="user-grey"
        iconSize="md"
        label="Receiver name as per IC"
        variant="filled"
      />
      <CustomTextField
        iconName="phone"
        iconSize="md"
        label="Receiver mobile number"
        variant="filled"
      />
      <CustomTextField
        iconName="mail"
        iconSize="sm"
        type="email"
        label="Receiver email address"
        variant="filled"
      />

      <div className="checkbox-section">
        <Checkbox
          color="primary"
          checked={checked}
          onChange={e => setChecked(!checked)}
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <Typography variant="subtitle1">
          The above information are correct
        </Typography>
      </div>

      <Button variant="contained" onClick={handleDoAction}>
        Confirm
      </Button>
      <Button
        disableRipple
        variant="text"
        className="underline"
        onClick={handleDoSecondaryAction}
      >
        Cancel
      </Button>
    </Dialog>
  );
};

export { ConfirmBookingModal };
