import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { Button, Link } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CustomIcon } from "components/common/CustomIcon/CustomIcon";
import "./Modal.scss";
import { render } from "@testing-library/react";

const PopUpModal = (props) => {
  const {
    title,
    subtitle,
    warningSubtitle,
    img,
    checkboxLabel,
    btnLabel,
    secondaryBtnLabel,
    action,
    secondaryAction,
    showModal,
    setShowModal,
    showMapBtn = false,
    isCheckout = false,
    checkedTnc,
    setCheckedTnc,
    showMapAction,
  } = props;
  // Pass Props from parent for button action, label and img

  const handleClose = () => {
    setShowModal(false);
  };
  const handleClickTNC = () => {
    window.open(`${window.location.origin}/tnc`);
  };
  //
  return (
    <Dialog className="Modal" onClose={handleClose} open={showModal}>
      <div className="pop-up">
        <div className="btn-close-container">
          <Typography variant="h5">{title}</Typography>
          <IconButton
            aria-label="close"
            className="btn-close"
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <div className="subtitles">
          <div className="inner-subtitles">
            {warningSubtitle && (
              <>
                <div className="icon-container">
                  <CustomIcon
                    iconName="warning"
                    className="icon-warning"
                    size="sm"
                  />
                </div>
                <Typography variant="body1" className="warning">
                  {warningSubtitle}
                </Typography>
              </>
            )}
            {subtitle && !showMapBtn && (
              <Typography variant="body1" style={{ textAlign: "center" }}>
                {subtitle}
              </Typography>
            )}
          </div>
        </div>
        <div className="subtitles-left">
          {isCheckout && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body1" style={{ textAlign: "left" }}>
                Ensure your parcel is:
              </Typography>
              <Typography variant="body1" style={{ textAlign: "left" }}>
                &#10004; Below 2 kg
              </Typography>
              <Typography variant="body1" style={{ textAlign: "left" }}>
                &#10004; Fit 30cm x 42cm Flyer Bag
              </Typography>
              <Typography variant="body1" style={{ textAlign: "left" }}>
                &#10004; Suitable for Room Temperature
              </Typography>
            </div>
          )}
        </div>

        {isCheckout && (
          <div className="checkbox">
            <FormGroup>
              <FormControlLabel
                className="checkbox-label"
                control={
                  <Checkbox
                    // defaultChecked
                    color="primary"
                    checked={checkedTnc}
                    onChange={() => setCheckedTnc(!checkedTnc)}
                  />
                }
                label={
                  <div>
                    {/* <b>I Agree to the </b>
                    <Link>Terms</Link>
                    <b> and </b>
                    <Link>Conditions</Link> */}
                    <b>I Agree to the </b>
                    <Link onClick={handleClickTNC}>Terms & Conditions</Link>
                  </div>
                }
              />
            </FormGroup>
          </div>
        )}

        {checkboxLabel ? (
          <div className="checkbox">
            <FormGroup>
              <FormControlLabel
                className="checkbox-label"
                control={<Checkbox defaultChecked />}
                label={checkboxLabel}
              />
            </FormGroup>
          </div>
        ) : null}

        {img && (
          <img
            src={`${process.env.PUBLIC_URL}/images/${img}.png`}
            alt="content image"
          />
        )}
        {showMapBtn && (
          <>
            <Typography variant="body1">
              Kindly contact our sorting facility to arrange an appointment to
              pick up your parcel, at least 1 working day in advance
            </Typography>
            <Typography variant="body1">
              Email: <br />
              PUDO.sortinghub@pktgroup.com
            </Typography>
            <Typography variant="body1">
              Address: <br />
              {subtitle}
            </Typography>
            <Typography variant="body1">
              Collection hours: <br />
              3pm - 5pm on working days
            </Typography>
            <Button
              variant="outlined"
              onClick={showMapAction}
              className="show-map-btn"
            >
              Show on Google Map
            </Button>
          </>
        )}
        <Button variant="contained" onClick={action}>
          {btnLabel}
        </Button>
        {secondaryBtnLabel && (
          <Button variant="outlined" onClick={secondaryAction}>
            {secondaryBtnLabel}
          </Button>
        )}
      </div>
    </Dialog>
  );
};

export { PopUpModal };
