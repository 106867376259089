import React from "react";
import Container from "@material-ui/core/Container";
import "./Background.scss";

const Background = (props) => {
  const { fullHeight, color, children, noMaxWidth, justifyCenter, ...rest } =
    props;
  return (
    <div
      className={`Background ${color} ${!!fullHeight && "h-screen"} ${
        !!justifyCenter && "content-center"
      }`}
      {...rest}
    >
      <Container maxWidth={!!noMaxWidth}>{children}</Container>
    </div>
  );
};

export { Background };
