import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useLocationPickerModalStore } from "states/locationPickerModalState";
import { useTheme } from "@material-ui/core/styles";
import { Marker } from "components/common/Marker/Marker";
import { SearchBar } from "components/common/SearchBar/SearchBar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import GoogleMapReact from "google-map-react";
// import { SAMPLE_LAT_LNG_MARKERS } from "constants/constants";
import "./LocationPickerModal.scss";
import { useStoreContext } from "contexts/StoreContext";
import { API, graphqlOperation } from "aws-amplify";
import { getStoreList } from "graphql/queries";
import { CustomIcon } from "components/common/CustomIcon/CustomIcon";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import scriptLoader from "react-async-script-loader";
import Typography from "@material-ui/core/Typography";
import { MyLocation } from "@material-ui/icons";
import { setCustomSnackbarState } from "states/customSnackbarState";
import Autosuggest from "react-autosuggest";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const LocationPickerModal = () => {
  const [modalStore] = useLocationPickerModalStore();
  const [showModal, setShowModal] = useState(false);
  const [selectedLocationMarker, setSelectedLocationMarker] = useState("");
  const [displayType, setDisplayType] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLgAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const { setSelectedCollectionStore, setSelectedDropOffStore } =
    useStoreContext();
  const [storeList, setStoreList] = useState([]);
  const [currentUserLocation, setCurrentUserLocation] = useState(null);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionValue, setSuggestionValue] = useState("");

  //test
  const [address, setAddress] = useState("");

  useEffect(() => {
    if (modalStore.show) {
      setShowModal(true);
    }
    if (modalStore.type === "dropOff") {
      setDisplayType("drop-off");
    } else {
      setDisplayType("collection");
    }
    setAddress("");
    setSuggestionValue("");
  }, [modalStore]);

  useEffect(() => {
    if (modalStore.show === true) {
      fetchLocationData();
      navigator.geolocation.getCurrentPosition(success);
    }
  }, [modalStore]);

  function success(pos) {
    var crd = pos.coords;

    setCurrentUserLocation({
      center: {
        lat: crd.latitude,
        lng: crd.longitude,
      },
      zoom: 14,
    });
  }
  async function fetchLocationData() {
    // setIsLoading(true);
    const res = await API.graphql(graphqlOperation(getStoreList, {}));
    if (res && res.data.getStoreList.message === "Success") {
      // setIsLoading(false);
      setStoreList(res.data.getStoreList.storeList);
    }
  }

  const handleClose = () => {
    setShowModal(false);
  };

  const defaultProps = {
    center: {
      lat: 3.19755602,
      lng: 101.50102627,
    },
    zoom: 14,
  };

  const handleConfirmLocation = (locationName) => {
    if (
      selectedLocationMarker.storageAvailable !== true &&
      displayType === "drop-off"
    ) {
      setCustomSnackbarState({
        show: true,
        title: "Store Unavailable, Please Select Another Store",
        variant: "error",
      });
      return;
    }
    modalStore.setSelectedLocation((prev) => ({
      ...prev,
      [modalStore.type]: selectedLocationMarker.title,
    }));
    // set the store to store context
    if (displayType === "drop-off") {
      setSelectedDropOffStore(selectedLocationMarker);
    }
    if (displayType === "collection") {
      setSelectedCollectionStore(selectedLocationMarker);
    }
    setSelectedLocationMarker("");
    handleClose();
  };

  const handleChangeAddress = (value) => {
    if (!address.includes("FamilyMart")) {
      setAddress("FamilyMart " + value);
    } else if (value === "FamilyMar" && address === "FamilyMart") {
      setAddress("");
    } else {
      setAddress(value);
    }
  };

  const handleSelectAddress = (value) => {
    let googleStoreName = value.split(",")[0];

    geocodeByAddress(value)
      .then((results) => {
        return getLatLng(results[0]);
      })
      .then((latLng) =>
        setCurrentUserLocation({
          center: latLng,
          zoom: 20,
        })
      )
      .catch((error) => {
        // setCustomSnackbarState({
        //   show: true,
        //   title: "Error retrieving the location selected.",
        //   variant: "error",
        // });
      });
    setAddress(value);
    //
    // auto select store after user select google api respose suggestion
    storeList.map((item) => {
      if (item.title.split(",")[0] === googleStoreName) {
        setSelectedLocationMarker(item);
      } else if (item.mappingStore === googleStoreName) {
        setSelectedLocationMarker(item);
      }
    });
  };

  const handleOnClickMyLocation = () => {
    navigator.geolocation.getCurrentPosition(success);
  };

  const onFetchingLocationsError = (status, clearSuggestions) => {
    // setCustomSnackbarState({
    //   show: true,
    //   title: "Google Maps API returned error with status: ', status",
    //   variant: "error",
    // });
    clearSuggestions();
  };
  function createMapOptions() {
    return {
      gestureHandling: "greedy", // Will capture all touch events on the map towards map panning
    };
  }
  const searchOptions = {
    // location: new window.google?.maps.LatLng(currentUserLocation.center.lat, currentUserLocation.center.lng),
    // location: {
    //   lat: currentUserLocation?.center.lat
    //     ? currentUserLocation?.center.lat
    //     : 3.19755602,
    //   lng: currentUserLocation?.center.lng
    //     ? currentUserLocation?.center.lng
    //     : 101.50102627,
    // },
    // radius: 2000,
    // types: ['address']
    componentRestrictions: { country: "MY" },
  };
  const getSuggestions = (value) => {
    let _storeList = [];
    storeList.map((item) => {
      if (
        (item.title.toLowerCase().includes(value.toLowerCase()) ||
          item.address.includes(value)) &&
        _storeList.length < 5
      )
        _storeList.push(item.title);
    });
    return _storeList;
  };
  const handleOnSuggestionSelected = (e, obj) => {
    console.log(e);
    console.log(obj);
    let selectedLocation;
    storeList.map((item) => {
      if (item.title === obj.suggestion) {
        selectedLocation = item;
        setCurrentUserLocation({
          center: {
            lat: parseFloat(item.lat),
            lng: parseFloat(item.lng),
          },
          zoom: 16,
        });
      }
    });
    console.log(selectedLocation);

    setSelectedLocationMarker(selectedLocation);
  };
  console.log(selectedLocationMarker);
  console.log(currentUserLocation);
  return (
    <Dialog
      onClose={handleClose}
      scroll="paper"
      className={`LocationPickerModal ${isLgAndUp ? "lg-dialog" : ""}`}
      fullScreen={fullScreen}
      open={showModal}
    >
      <div className="searchbar-section">
        <IconButton
          aria-label="close"
          className="btn-close-modal"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <div className="container-maps-search-bar">
          <div className="auto-suggest-container">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={({ value }) => {
                setSuggestionValue(value);
                setSuggestions(getSuggestions(value));
              }}
              onSuggestionsClearRequested={() => setSuggestions([])}
              getSuggestionValue={(storeList) => storeList.title}
              renderSuggestion={(suggestion) => <span>{suggestion}</span>}
              inputProps={{
                placeholder: "Please insert store title...",
                value: suggestionValue,
                onChange: (newValue, method) => {
                  console.log(newValue);
                  setSuggestionValue(newValue.target.innerText);
                  setSelectedLocationMarker("");
                },
              }}
              onSuggestionSelected={handleOnSuggestionSelected}
            />
            {suggestionValue.length > 0 && (
              <a
                style={{ cursor: "pointer" }}
                className="clear-btn"
                onClick={() => setSuggestionValue("")}
              >
                <CloseOutlinedIcon />
              </a>
            )}
          </div>
          {/* <PlacesAutocomplete
            value={address}
            onChange={handleChangeAddress}
            onSelect={handleSelectAddress}
            onError={onFetchingLocationsError}
            searchOptions={searchOptions}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <>
                <SearchBar
                  variant="search"
                  // title={`Search ${displayType} location`}
                  title={`Please type your postcode here... `}
                  searchFilter={address}
                  setSearchFilter={setAddress}
                  leftIcon={
                    <MyLocation
                      className="cursor"
                      onClick={handleOnClickMyLocation}
                    />
                  }
                  customProps={{
                    ...getInputProps(),
                  }}
                />
                <div className="container-search-autocomplete">
                  {loading && (
                    <div className="item-autocomplete">
                      <Typography
                        variant="body1"
                        className="main-text-item-autocomplete"
                      >
                        {"Loading..."}
                      </Typography>
                    </div>
                  )}
                  {suggestions.map((suggestion) => {
                    const suggestionStylingClassName = suggestion.active
                      ? "selected"
                      : "not-selected";
                    // if (
                    //   suggestion?.formattedSuggestion?.mainText?.includes(
                    //     "FamilyMart"
                    //   ) ||
                    //   suggestion?.formattedSuggestion?.mainText?.includes(
                    //     "Family Mart"
                    //   )
                    // ) {
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className: `item-autocomplete ${suggestionStylingClassName}`,
                        })}
                      >
                        <Typography
                          variant="body1"
                          className="main-text-item-autocomplete"
                        >
                          {suggestion.formattedSuggestion.mainText}
                        </Typography>
                        <Typography variant="body2">
                          {`${suggestion.formattedSuggestion.mainText} ${
                            suggestion.formattedSuggestion.secondaryText ?? ""
                          }`}
                        </Typography>
                      </div>
                    );
                    // }
                  })}
                </div>
              </>
            )}
          </PlacesAutocomplete> */}
        </div>
      </div>

      {/* Google Maps UI */}
      <div style={{ height: "75%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyCTJyEqaeS7J6355E4Biw8oyWO5Q3Lpq24" }}
          center={
            currentUserLocation
              ? currentUserLocation.center
              : defaultProps.center
          }
          defaultZoom={
            currentUserLocation ? currentUserLocation.zoom : defaultProps.zoom
          }
          zoom={
            currentUserLocation ? currentUserLocation.zoom : defaultProps.zoom
          }
          options={createMapOptions}
          // onClick={()=>setSelectedLocationMarker("")}
          onDrag={(map) => setSelectedLocationMarker("")}
        >
          {storeList.map((m) => (
            <Marker
              lat={m.lat}
              lng={m.lng}
              item={m}
              selectedLocationMarker={selectedLocationMarker.title}
              handleSelect={setSelectedLocationMarker}
            />
          ))}
          {currentUserLocation !== null && (
            <div
              className={`Marker cursor`}
              lat={currentUserLocation.center.lat}
              lng={currentUserLocation.center.lng}
            >
              <CustomIcon iconName="location-pin-2" size="xl" />
            </div>
          )}
        </GoogleMapReact>
      </div>

      <div className="mx-4">
        <Button
          variant="contained"
          className="mt-4 "
          onClick={handleConfirmLocation}
        >
          {`Confirm ${displayType} location`}
        </Button>
      </div>

      {/* Old UI */}
      {/* <CurrentLocationCard handleSelect={handleSelect} />

      <Typography variant="body1" className="section-title">
        Recent {displayType} location
      </Typography>

      {RECENT_DROP_OFF_LOCATIONS.map((r, i) => (
        <LocationCard item={r} key={i} handleSelect={handleSelect} />
      ))}

      <Typography variant="body1" className="section-title">
        Other locations
      </Typography>

      {LOCATION_LISTS.map((r, i) => (
        <LocationCard item={r} key={i} handleSelect={handleSelect} />
      ))} */}
    </Dialog>
  );
};

// const CurrentLocationCard = props => {
//   const { handleSelect } = props;
//   const [geolocation, setGeolocation] = useState({
//     latitude: "",
//     longitude: ""
//   });

//   useEffect(() => {
//     const getLocation = () => {
//       if (navigator.geolocation) {
//         navigator.geolocation.getCurrentPosition(showPosition);
//       } else {
//
//       }
//     };
//     getLocation();
//   }, []);

//   const showPosition = position => {
//     setGeolocation(position.coords);
//   };

//   return (
//     <Card
//       elevation={0}
//       onClick={() =>
//         geolocation.latitude && geolocation.longitude
//           ? handleSelect(
//               `Latitude: ${geolocation.latitude}, Longitude: ${geolocation.longitude}`
//             )
//           : {}
//       }
//       className="current-location-card"
//     >
//       <CardContent className="d-flex">
//         <MyLocationIcon color="primary" className="icon-location" />
//         <div>
//           <Typography variant="h6" className="title">
//             Your current location
//           </Typography>
//           <Typography variant="body2" className="address">
//             {geolocation.latitude && geolocation.longitude
//               ? `Latitude: ${geolocation.latitude}, Longitude: ${geolocation.longitude}`
//               : "Loading..."}
//           </Typography>
//         </div>
//       </CardContent>
//     </Card>
//   );
// };

// const LocationCard = props => {
//   const { item, handleSelect } = props;
//   return (
//     <Card
//       onClick={() =>
//         item.status === "available" ? handleSelect(item.locationName) : {}
//       }
//       elevation={0}
//       className={`location-card ${
//         item.status !== "available" ? "disabled" : ""
//       }`}
//     >
//       <CardContent>
//         <div className="location-title-section">
//           <Typography variant="h6" className="title">
//             {item.locationName}
//           </Typography>
//           <Typography variant="body1" className="distance">
//             {item.distance}
//           </Typography>
//         </div>
//         <Typography variant="body2" className="address">
//           {item.address}
//         </Typography>
//         <Typography variant="body2" className="description">
//           {item.description}
//         </Typography>
//         <Typography variant="subtitle1" className="status">
//           {item.status}
//         </Typography>
//       </CardContent>
//     </Card>
//   );
// };

//lazy load google map js script
export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API}&libraries=places`,
])(LocationPickerModal);
